import gql from 'graphql-tag'

export const PMS_CSV_REPORT = gql`
mutation (
  $fromDate: DateString!
  $toDate: DateString! 
  $facilityId: Int 
  $type: PmsReportType! 
) {
  pmsCsvReport(
    fromDate: $fromDate, toDate: $toDate, facilityId: $facilityId, type: $type
  ) {
    files {
      fileName 
      downloadUrl 
    }
  }
}`
